@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-Thin.woff') format('woff'),
       url('../theme/fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
       url('../theme/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
  url('../theme/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-SemiBold.woff') format('woff'),
       url('../theme/fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
  url('../theme/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
  url('../theme/fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../theme/fonts/Gilroy/Gilroy-Heavy.woff') format('woff'),
  url('../theme/fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Handlee';
  src: local('Handlee'), url(../theme/fonts/Handlee-Regular.ttf) format('woff');
}
